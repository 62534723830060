<template>
  <WithQuery :base-filters="baseFilters">
    <div slot-scope="{ filters }">
      <ReportsListView
        view-title="Viewing Locked Reports"
        :items-per-page="15"
        :filters="filters"
      >
        <template v-if="isDateRangeFilterOn" slot="subtitle"
          ><h2>{{ subTitle }}</h2>
        </template>
        <template slot="header-buttons">
          <BaseDateRangeControls />
        </template>
      </ReportsListView>
    </div>
  </WithQuery>
</template>

<script>
import * as appConfig from '@src/app.config'
import ReportsListView from '@components/ReportsListView/ReportsListView'
import WithQuery from '@components/DataProviders/WithQuery'
import dayjs from 'dayjs'

import { ReportStatuses, HumanFields } from '@constants/knack'

export default {
  name: 'LockedReportsListView',
  components: { ReportsListView, WithQuery },
  metaInfo: {
    title: 'Locked Reports',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {}
  },
  computed: {
    baseFilters() {
      return [
        {
          field: HumanFields.DAILY_REPORT.REPORT_STATUS,
          operator: 'is',
          value: ReportStatuses.LOCKED,
        },
      ]
    }, // baseFilters
    isDateRangeFilterOn() {
      return !!this.$route.query.startDate || !!this.$route.query.endDate
    }, // isDateRangeFilterOn
    subTitle() {
      let title
      let format = 'MM/DD/YYYY'
      if (this.$route.query.startDate && this.$route.query.endDate) {
        let parts = [
          dayjs(this.$route.query.startDate).format(format),
          dayjs(this.$route.query.endDate).format(format),
        ]
        title = `${parts.filter((e) => !!e).join(' - ')}`
      } else if (this.$route.query.startDate) {
        title = `After ${dayjs(this.$route.query.startDate).format(format)}`
      } else if (this.$route.query.endDate) {
        title = `Before ${dayjs(this.$route.query.endDate).format(format)}`
      }
      return `( ${title} )`
    }, // subTitle
  },
}
</script>
